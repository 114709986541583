import { Box, Button, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";

function PageEventDelete() {
  const [events, setEvents] = useState<any>([]);

  const event: any = React.useMemo(async () => {
    try {
      const res = await axios.get("/event");
      //   setLoadingEventName(false);
      //   setEventName(res.data);
      // return res.data;
      setEvents(res.data);
    } catch (err) {
      //   setLoadingEventName(false);
      console.log(err);
      return [];
    }
  }, []);

  function formatDate(dateStr: any) {
    const months = [
      "يناير",
      "فبراير",
      "مارس",
      "إبريل",
      "مايو",
      "يونيو",
      "يوليو",
      "أغسطس",
      "سبتمبر",
      "أكتوبر",
      "نوفمبر",
      "ديسمبر",
    ];

    const [year, month, day] = dateStr.split("-"); // يفترض أن تكون dateStr بتنسيق YYYY-MM-DD
    const monthIndex = parseInt(month) - 1; // -1 لأن الأشهر تبدأ من 0
    return `${months[monthIndex]}`;
  }

  const showDay = (dateStr: any) => {
    // تحويل النص إلى كائن Date
    const date = new Date(dateStr);

    // الحصول على اليوم (الرقمين الأخيرين)
    return date.getDate();
  };

  function handleDelete(id: any) {
    axios
      .delete(`/event/deleteEvent/${id}`, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <Flex justifyContent={"space-around"} flexWrap={"wrap"} pb={"30px"}>
      {events.map((event: any) => {
        // console.log(event.descEvent);
        return (
          <Box>
            <VStack
              zIndex="99"
              position={"relative"}
              left={250}
              top={7}
              bg={"white"}
              // border={"1px solid black"}
              borderRadius={"2xl"}
              h={"70px"}
              w={"fit-content"}
              minW={"70px"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Text color={"red"} fontWeight={"bold"}>
                {formatDate(event.date)}
              </Text>
              <Text color={"black"}>{showDay(event.date)}</Text>
            </VStack>

            <figure
              key={event._id}
              style={{ boxShadow: "0px 0px 10px 0px white" }}
            >
              <img src={event.image} alt="Events" loading="lazy" />
              <figcaption>
                <div className="event-info">
                  <h1>{event.nameEvent}</h1>
                  {/* <h3>{event.date}</h3> */}
                  <h3>
                    تبدأ أسعار التذاكر من :
                    {Math.min(...event.descEvent.map((e: any) => e.price))}
                  </h3>
                  <h3> {event.placeEvent} :الموقع </h3>
                  <HStack justify={"center"} spacing={"12"}>
                    {/* <Link to={`/ticketspage/${event._id}`}>
                      <Button
                        fontSize={"sm"}
                        fontWeight={600}
                        color={"white"}
                        bg={"pink.600"}
                        _hover={{
                          bg: "pink.800",
                        }}
                      >
                        شراء تذكرة
                      </Button>
                    </Link> */}
                    <Button
                      fontSize={"sm"}
                      fontWeight={600}
                      color={"white"}
                      bg={"pink.600"}
                      _hover={{
                        bg: "pink.800",
                      }}
                      onClick={() => handleDelete(event._id)}
                    >
                      حذف
                    </Button>
                  </HStack>
                </div>
              </figcaption>
            </figure>
          </Box>
        );
      })}
    </Flex>
  );
}

export default PageEventDelete;
