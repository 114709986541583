import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { Box } from "@chakra-ui/react";

function FooterLayout() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh" // يضمن أن الغلاف يكون بارتفاع الشاشة
    >
      <Box flex="1">
        {" "}
        {/* يسمح لـ Outlet بالتمدد لملء المساحة المتبقية */}
        <Outlet />
      </Box>
      <Footer /> {/* يكون دائمًا في أسفل الصفحة */}
    </Box>
  );
}

export default FooterLayout;
