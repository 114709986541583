import axios from "axios";
import React, { useEffect, useState } from "react";
import { UserContext } from "../components/ContextUser";
import { useNavigate, useParams } from "react-router-dom";
import { Box, useToast } from "@chakra-ui/react";

function PageMADA() {
  const { _id } = useParams();
  const { userInfo } = UserContext();
  const [checkoutId, setCheckoutId] = useState("");
  const [paymentBrand, setPaymentBrand] = useState("MADA");
  const [loading, setLoading] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const makeform = () => {
      const head = document.getElementsByTagName("head")[0];
      axios
        .post(
          `/hyperpay/makePayment/${_id}`,
          {
            userBuy_id: userInfo._id,
            paymentBrand: paymentBrand,
            email: userInfo.email,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res: any) => {
          setLoading(false);
          setCheckoutId(res.data.paymentResponse.id);
          setTransactionId(res.data.transactionId);
          // إنشاء وإضافة script جديد
          const script = document.createElement("script");
          script.src = `https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=${res.data.paymentResponse.id}`;
          head.appendChild(script);
        })
        .catch((error) => {
          setLoading(false);
          if (
            error.response.data.message ===
            "التذكرة غير متاحة أو قيد الاستخدام."
          ) {
            toast({
              title: "خطأ",
              description: "التذكرة غير متاحة أو قيد الاستخدام.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            return navigate(`/`);
          }
        });
    };
    if (_id) {
      makeform();
    }
  }, []);
  return (
    <Box
      backgroundColor={"currentcolor"}
      minH={"71.7vh"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      {paymentBrand ? (
        <form
          action={`/shopperResultUrl/${checkoutId}/${paymentBrand}/${_id}/${transactionId}`}
          className="paymentWidgets"
          // onSubmit={makePaymentHyperPay}
          data-brands={"MADA"}
        ></form>
      ) : null}
    </Box>
  );
}

export default PageMADA;
