import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

const AuthContext = createContext<any>({});
function AuthContextProvider(props: any) {
  const [loggedIn, setLoggedIn] = useState(false);

  const getLoggedIn = () => {
    axios
      .get("/user/loggedIn")
      .then((res: any) => {
        setLoggedIn(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getLoggedIn();
  }, []);
  return (
    <AuthContext.Provider value={{ loggedIn, setLoggedIn, getLoggedIn }}>
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
export { AuthContextProvider };
