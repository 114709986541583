import { Text } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

function ConfirmUserPage() {
  const { email } = useParams();
  console.log(email);

  useEffect(() => {
    axios
      .post(`/user/confirm/${email}`)
      .then((res) => {
        console.log(res);
        // window.location.href = "/login";
      })
      .catch((err) => {
        console.log(err);
      });
  }, [email]);

  return (
    <div>
      <Text>تم تأكيد تسجيلك</Text>
    </div>
  );
}

export default ConfirmUserPage;
