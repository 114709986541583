import {
  Box,
  Button,
  Flex,
  HStack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../components/home.css";
import HomeHead from "../components/HomeHead";
import { UserContext } from "../components/ContextUser";
import LoadingEvent from "../components/LoadingEvent";
import { ChatIcon, ChevronDownIcon } from "@chakra-ui/icons";
import SlideMessages from "../components/SlideMessage";
import AuthContext from "../context/AuthContext";

interface dataEvent {
  _id: string;
  nameEvent: string;
  image: string;
  price: number;
  date: string;
  time: number;
  placeEvent: string;
}
interface dataEventName {
  _id: string;
  nameEvent: string;
}
function Home() {
  const navigate = useNavigate();
  const { onClose, onOpen, isOpen } = useDisclosure();
  const { loggedIn } = useContext(AuthContext);
  const { userInfo } = UserContext();
  const [price, setPrice] = useState();
  const [val, setVal] = useState<string>("");
  const [eventName, setEventName] = useState<dataEventName[]>([
    { _id: "", nameEvent: "" },
  ]);
  const [events, setEvents] = useState<any>([]);
  const [loadingEventName, setLoadingEventName] = useState<boolean>(false);
  // useEffect(() => {
  //   setLoading(true);
  //   axios
  //     .get("/event")
  //     .then((res) => {
  //       setLoading(false);
  //       setEventName(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setLoading(false);
  //     });
  // }, [events]);

  const event: any = React.useMemo(async () => {
    setLoadingEventName(true);
    try {
      const res = await axios.get("/event");
      setLoadingEventName(false);
      setEventName(res.data);
      // return res.data;
    } catch (err) {
      setLoadingEventName(false);
      console.log(err);
      return [];
    }
  }, []); // فارغة للتأكد من أن الاستعلام يتم مرة واحدة عند التحميل الأولي فقط

  // useEffect(() => {
  //   if (event.length === 0) {
  //     setLoading(true); // إذا كانت قائمة الأحداث فارغة، قم بتعيين الحالة المحلية للتحميل
  //   } else {
  //     setLoading(false); // إذا كانت هناك بيانات في قائمة الأحداث، قم بإيقاف حالة التحميل
  //   }
  // }, [event]);

  useEffect(() => {
    axios
      .get(`/event/search?nameEvent=${val}`)
      .then((res) => {
        setEvents(res.data);
      })
      .catch((err) => console.log(err));
  }, [val]);

  // useEffect(() => {
  //   // قم بحذف الأحداث عند الحاجة
  //   axios
  //     .delete("/event")
  //     .then(() => {
  //       // بعد حذف الأحداث، قم بإعادة جلب الأحداث
  //       axios
  //         .get("/event")
  //         .then((res) => {
  //           setEventName(res.data);
  //           setEvents(res.data);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  //if user click back after logIn
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (loggedIn) {
      window.history.pushState(null, window.location.pathname);
      window.addEventListener("popstate", handlePopstate);
    }

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  const handlePopstate = () => {
    window.history.pushState(null, window.location.pathname);
  };

  const pageSale = (id: any) => {
    const token = localStorage.getItem("token");
    if (!loggedIn) {
      return navigate("/login");
    }
    navigate(`/saleticket/${id}`);
  };
  useEffect(() => {
    setPrice(userInfo);
  }, [userInfo]);

  function formatDate(dateStr: any) {
    const months = [
      "يناير",
      "فبراير",
      "مارس",
      "إبريل",
      "مايو",
      "يونيو",
      "يوليو",
      "أغسطس",
      "سبتمبر",
      "أكتوبر",
      "نوفمبر",
      "ديسمبر",
    ];

    const [year, month, day] = dateStr.split("-"); // يفترض أن تكون dateStr بتنسيق YYYY-MM-DD
    const monthIndex = parseInt(month) - 1; // -1 لأن الأشهر تبدأ من 0
    return `${months[monthIndex]}`;
  }

  const showDay = (dateStr: any) => {
    // تحويل النص إلى كائن Date
    const date = new Date(dateStr);

    // الحصول على اليوم (الرقمين الأخيرين)
    return date.getDate();
  };

  return (
    <Box backgroundColor={"currentcolor"} h={"full"} minH={"135vh"}>
      <HomeHead
        loadingEventName={loadingEventName}
        eventName={eventName}
        handlerChange={(e: any) => setVal(e.target.value)}
      />

      <Box
        // bg={"blue"}
        position={"fixed"}
        bottom="30"
        right={"5"}
        // left="1280"
        backgroundColor="#0074c2"
        color="#fff"
        borderRadius="50%"
        width="50px"
        height="50px"
        fontSize="20px"
        zIndex="99999999999999999999999"
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        cursor={"pointer"}
        fontWeight={"bold"}
        // _hover={{ padding: "28px" }}
        onClick={isOpen ? () => onClose() : () => onOpen()}
      >
        {isOpen ? (
          <ChevronDownIcon fontSize={"30px"} color={"white"} />
        ) : (
          <ChatIcon color={"white"} />
        )}
      </Box>
      {userInfo.isAdmin ? (
        <VStack>
          <Button onClick={() => navigate("/pageadmin")}>فحص التذاكر</Button>
          <Button onClick={() => navigate("/addevent")}>أضافة فعالية</Button>
          <Button onClick={() => navigate("/deleteevent")}>حذف فعالية</Button>
        </VStack>
      ) : null}

      {loadingEventName ? (
        <LoadingEvent />
      ) : (
        <Flex justifyContent={"space-around"} flexWrap={"wrap"} pb={"30px"}>
          {events.map((event: any) => {
            // console.log(event.descEvent);
            return (
              <Box>
                <VStack
                  zIndex="99"
                  position={"relative"}
                  left={250}
                  top={7}
                  bg={"white"}
                  // border={"1px solid black"}
                  borderRadius={"2xl"}
                  h={"70px"}
                  w={"fit-content"}
                  minW={"70px"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Text color={"red"} fontWeight={"bold"}>
                    {formatDate(event.date)}
                  </Text>
                  <Text color={"black"}>{showDay(event.date)}</Text>
                </VStack>

                <figure
                  key={event._id}
                  style={{ boxShadow: "0px 0px 10px 0px white" }}
                >
                  <img src={event.image} alt="Events" loading="lazy" />
                  <figcaption>
                    <div className="event-info">
                      <h1>{event.nameEvent}</h1>
                      {/* <h3>{event.date}</h3> */}
                      <h3>
                        تبدأ أسعار التذاكر من :
                        {Math.min(...event.descEvent.map((e: any) => e.price))}
                      </h3>
                      <h3> {event.placeEvent} :الموقع </h3>
                      <HStack justify={"center"} spacing={"12"}>
                        <Link to={`/ticketspage/${event._id}`}>
                          <Button
                            fontSize={"sm"}
                            fontWeight={600}
                            color={"white"}
                            bg={"pink.600"}
                            _hover={{
                              bg: "pink.800",
                            }}
                          >
                            شراء تذكرة
                          </Button>
                        </Link>
                        <Button
                          fontSize={"sm"}
                          fontWeight={600}
                          color={"white"}
                          bg={"pink.600"}
                          _hover={{
                            bg: "pink.800",
                          }}
                          onClick={() => pageSale(event._id)}
                        >
                          بيع تذكرتك
                        </Button>
                      </HStack>
                    </div>
                  </figcaption>
                </figure>
              </Box>
            );
          })}
        </Flex>
      )}
      <Box>
        <SlideMessages boo={isOpen} onClose={onClose} />
      </Box>
    </Box>
  );
}

export default Home;
