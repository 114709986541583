import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Input,
  Text,
  Textarea,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { UserContext } from "./ContextUser";
function SlideMessages({ boo, onClose }: any) {
  const [msg, setMsg] = useState("");
  const [email, setEmail] = useState("");
  const [err, setErr] = useState("");
  const toast = useToast();
  const { userInfo } = UserContext();
  const postMsg = () => {
    axios
      .post("/message", { message: msg, email: email })
      .then((res) => {
        onClose();
        return toast({
          title: "success",
          description: "شكرا لتواصلكم",
          status: "success",
          position: "top",
          duration: 3000,
          isClosable: true,
          colorScheme: "blue",
        });
      })
      .catch((err) => {
        toast({
          title: "faild",
          description: err.response.data.message,
          status: "error",
          position: "top",
          duration: 2000,
          isClosable: true,
        });
        console.log(err);
      });
  };

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   if (!token) {
  //     setEmail("");
  //   } else {
  //     setEmail(userInfo.email);
  //   }
  // }, []);

  return (
    <>
      <Drawer
        isOpen={boo}
        placement="right"
        onClose={onClose}
        // finalFocusRef={btnRef}
        // btnRef={props.onclose}
      >
        <DrawerOverlay />
        {/* <DrawerCloseButton /> */}
        <DrawerContent
          dir="rtl"
          bg={"gray.600"}
          h={"70vh"}
          w={"400px"}
          mt={"100px"}
          mr={"30px"}
          color={"white"}
        >
          <DrawerHeader
            bg={"red"}
            borderBottomStartRadius={"50px"}
            textAlign={"center"}
          >
            تواصل معنا{" "}
          </DrawerHeader>

          <DrawerBody mt={"40px"}>
            <VStack justifyContent={"space-around"} h={"70%"}>
              {localStorage.getItem("token") ? (
                <Input
                  dir="ltr"
                  type="text"
                  value={userInfo.email}
                  placeholder={"email@example.com"}
                  onChange={(e) => setEmail(e.target.value)}
                  isRequired
                />
              ) : (
                <Input
                  dir="ltr"
                  type="text"
                  placeholder={"email@example.com"}
                  onChange={(e) => setEmail(e.target.value)}
                  isRequired
                />
              )}

              <Textarea
                isRequired
                placeholder="اكتب رسالتك"
                onChange={(e) => setMsg(e.target.value)}
              />
              <Button
                onClick={postMsg}
                color={"white"}
                bg={"blue.400"}
                _hover={{
                  bg: "blue.300",
                }}
              >
                ارسال
              </Button>
            </VStack>
          </DrawerBody>

          <DrawerFooter zIndex={"9999999999"} position={"relative"}>
            <Button
              variant="outline"
              color={"white"}
              mr={3}
              onClick={onClose}
              _hover={{ bg: "whiteAlpha.400" }}
            >
              أغلاق
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default SlideMessages;
