import {
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  HStack,
  Heading,
  Image,
  Input,
  Select,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FUNDING, PayPalButtons } from "@paypal/react-paypal-js";
import { UserContext } from "../components/ContextUser";
import { CalendarIcon } from "@chakra-ui/icons";
import PurchaseConfirm from "../components/PurchaseConfirm";
import DropIn, { IDropInProps } from "braintree-web-drop-in-react";
import useBraintreeDropin from "braintree-web-drop-in-react";
import LoadingProcess from "../components/LoadingProcess";
import AuthContext from "../context/AuthContext";
interface dataEvent {
  _id: string;
  eventName: string;
  image: string;
  price: number;
  date: string;
  time: number;
  placeEvent: string;
  imageSeats: string;
}
interface CustomBraintreeHooks {
  create: any; // استبدل "any" بنوع القيمة المتوقعة لـ "create"
  paypal: any; // استبدل "any" بنوع القيمة المتوقعة لـ "paypal"
}
interface dataTicket {
  _id: string;
  price: any;
  category: string;
  seat: string;
  user_id: string;
  event_id: string;
  isSold: boolean;
}
function PayPage() {
  const { userInfo } = UserContext();
  const { setLoggedIn } = useContext(AuthContext);
  const { _id } = useParams();
  const [loading, setLoading] = useState(false);
  const [infoTicket, setInfoTicket] = useState<dataTicket>({
    _id: "",
    price: "",
    category: "",
    seat: "",
    user_id: "",
    event_id: "",
    isSold: false,
  });
  const [infoEvent, setInfoEvent] = useState<dataEvent>({
    _id: "",
    eventName: "",
    image: "",
    price: 0,
    date: "",
    time: 0,
    placeEvent: "",
    imageSeats: "",
  });
  const [values, setValues] = useState<any>({
    clientToken: null,
    success: "",
    error: "",
  });
  const [checkoutId, setCheckoutId] = useState();
  const [card, setCard] = useState<any>({
    cvv: "",
    holder: "",
    number: "",
    expiry: "",
  });
  const [paymentBrand, setPaymentBrand] = useState("");
  const cardOptions = ["MADA", "VISA", "MASTER"];

  const [user, setUser] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  useEffect(() => {
    setUser(userInfo);
  }, [userInfo]);

  useEffect(() => {
    axios
      .get(`/ticket/ticketinfo/${_id}`, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log(res.data);
        const data = res.data;
        setInfoTicket(data);
      })
      .catch((err) => {
        console.log(err);
        toast({
          colorScheme: "pink",
          position: "top",
          title: "يجب عليك اعادة تسجيل الدخول ",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoggedIn(false);
        return navigate("/login");
      });
  }, [_id]);

  useEffect(() => {
    axios
      .get(`/event/id/${infoTicket.event_id}`)
      .then(async (res) => {
        const data = await res.data;
        // console.log(data);
        setInfoEvent(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [infoTicket]);
  // console.log(infoEvent);

  const makePaymentfetch = async (id: any) => {
    // setLoading(true);
    axios
      .get(`/hyperpay/makePayment/${id}/${_id}`)
      .then((res) => {
        console.log(res);
        setLoading(false);
        onOpen();
        setTimeout(() => {
          onClose();
        }, 5000);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const [formErrors, setFormErrors] = useState({
    cardNumber: false,
    cardHolder: false,
    cardExpiry: false,
    cardCVV: false,
    paymentBrand: false,
  });

  // const makePaymentHyperPay = async (e?: any) => {
  //   // e.preventDefault();
  //   setLoading(true);
  //   axios
  //     .post(`/hyperpay/makePayment/${_id}`, {
  //       userBuy_id: userInfo.id,
  //       card: card,
  //       paymentBrand: paymentBrand,
  //     })
  //     .then((res) => {
  //       console.log(res);
  //       // setPaymentBrand(e.target.value);
  //       setLoading(false);
  //       // shopper(res.data.id);
  //       // paymentWidgets(res.data.id);
  //       setCheckoutId(res.data.id);

  //       const head = document.getElementsByTagName("head")[0];
  //       const script = document.createElement("script");
  //       script.src = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${res.data.id}`;
  //       head.appendChild(script);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setLoading(false);
  //     });
  // };

  const handleChange = (event: any) => {
    let value = event.target.value;
    value = value.replace(/[^0-9]/g, ""); // قم بإزالة أي رموز غير أرقام

    if ((value.length === 3 || value.length === 4) && !value.endsWith("/")) {
      // إذا كانت الأرقام 3 ولم تنته بفاصلة، قم بإضافة الفاصلة تلقائيًا
      value = value.substring(0, 2) + "/" + value.substring(2);
    } else if (value.length > 5) {
      value = value.slice(0, 5);
    }

    setCard({ ...card, expiry: value });
  };

  return (
    <Box backgroundColor="#12132c" color="lavender" h="full" minH={"100vh"}>
      <Heading textAlign="right" padding="10px">
        تفاصيل الدفع
      </Heading>
      <HStack
        justifyContent="space-evenly"
        mt="90px"
        // marginBottom={{ base: "0px", md: "10px" }}
        flexWrap="wrap"
        alignItems="flex-start"
      >
        <Box
          m="5px"
          width={{ base: "300px", md: "500px" }}
          border="1px"
          borderColor="gray"
          borderTopLeftRadius="3xl"
          borderTopRightRadius="3xl"
        >
          <VStack>
            <Image
              loading="lazy"
              alt="picture"
              src={infoEvent.image}
              w="full"
              h="300px"
              borderTopLeftRadius="3xl"
              borderTopRightRadius="3xl"
            />
            <Text width="full" pr="10px" fontSize="2xl" textAlign="right">
              {infoEvent.eventName}
            </Text>
            <HStack alignSelf="flex-end" pr="5px" p="5px">
              <HStack borderRight="1px" pr="10px" borderColor="gray">
                <Text fontSize="2xl" color="gray" textAlign="right">
                  {infoEvent.date}
                </Text>
                <CalendarIcon color="blue" fontSize="larger" />
              </HStack>
              <HStack>
                <Text fontSize="2xl" color="gray" textAlign="right">
                  {infoEvent.placeEvent}
                </Text>
                <i
                  className="fas fa-map-marker-alt"
                  style={{ color: "green", fontSize: "larger" }}
                ></i>
              </HStack>
            </HStack>
          </VStack>
        </Box>

        <VStack
          width={{ base: "300px", md: "500px" }}
          borderColor="gray"
          borderRadius="3xl"
          pr="5px"
          display="flex"
          justifyContent="canter"
          alignItems="center"
        >
          <HStack
            border="3px solid white"
            w="104%"
            padding="10px"
            justifyContent="space-around"
            alignItems="center"
          >
            <HStack padding="5px">
              <Text
                textAlign="right"
                color="white"
                fontSize={{ base: "10px", md: "2xl" }}
              >
                {infoTicket.price}
              </Text>
              <Text
                textAlign="right"
                color="blue.400"
                fontSize={{ base: "sm", md: "2xl" }}
              >
                :السعر
              </Text>
            </HStack>
            <HStack>
              <Text
                textAlign="center"
                color="white"
                fontSize={{ base: "10px", md: "2xl" }}
              >
                1
              </Text>
              <Text
                textAlign="center"
                color="blue.400"
                fontSize={{ base: "sm", md: "2xl" }}
              >
                :التذاكر
              </Text>
            </HStack>
            <HStack>
              <Text
                textAlign="center"
                color="white"
                fontSize={{ base: "10px", md: "2xl" }}
              >
                {infoTicket.seat}
              </Text>
              <Text
                textAlign="center"
                color="blue.400"
                fontSize={{ base: "sm", md: "2xl" }}
              >
                :المقعد
              </Text>
            </HStack>
            <HStack>
              <Text
                textAlign="center"
                color="white"
                fontSize={{ base: "10px", md: "2xl" }}
              >
                {infoTicket.category}
              </Text>
              <Text
                textAlign="center"
                color="blue.400"
                fontSize={{ base: "sm", md: "2xl" }}
              >
                :الفئة
              </Text>
            </HStack>
          </HStack>
          <Box
            mb="10px"
            p="4"
            borderWidth="1px"
            borderRadius="md"
            boxShadow="md"
            maxW="sm"
            display="flex"
            justifyContent="space-between"
            h="240px"
            minW={"100%"}
            flexDir="column"
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"start"}
            >
              <Text fontSize={{ base: "sm", md: "2xl" }}>اختر طريقة الدفع</Text>
            </Box>
            <Box
              justifyContent={"space-between"}
              display={"flex"}
              flexDir={"column"}
            >
              <Button
                onClick={() => navigate(`/pageMADA/${_id}`)}
                colorScheme="gray"
                h={"65px"}
              >
                <Image src="/شعار مدى – SVG.svg" w="120px" h="150px" />
              </Button>
              <Button
                h={"65px"}
                onClick={() => navigate(`/pageVISA&MASTER/${_id}`)}
                colorScheme="gray"
                mt="2"
              >
                <Image src="/visa.svg" w="80px" h="60px" />
                <Image src="/mastercard.svg" w="80px" h="60px" />
              </Button>
            </Box>
          </Box>
        </VStack>
      </HStack>
      <PurchaseConfirm boo={isOpen} onClose={onClose} />
      {loading ? <LoadingProcess boo={true} /> : null}
    </Box>
  );
}

export default PayPage;

// const createOrder = (data: any) => {
//   // Order is created on the server and the order id is returned
//   return fetch(`http://localhost:3336/order/createOrder/${_id}`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       authorization: "Bearer " + localStorage.getItem("token"),
//     },
//     // use the "body" param to optionally pass additional order information
//     // like product skus and quantities
//     body: JSON.stringify({
//       product: {
//         price: infoTicket.price,
//       },
//     }),
//   })
//     .then((response) => {
//       if (response.status === 400 && response.status < 500) {
//         return toast({
//           title: "نعتذر منك تم شراء التذكرة من قبل مستخدم اخر",
//           status: "warning",
//           position: "bottom",
//           colorScheme: "pink",
//           isClosable: true,
//         });
//       }
//       return response.json();
//     })
//     .then((order: any) => order.id)
//     .catch((err) => {
//       console.log(err);
//       toast({
//         title: "حدث الصفحة او قم بأعادة تسجيل الدخول",
//         status: "warning",
//         position: "bottom-left",
//         colorScheme: "pink",
//         isClosable: true,
//       });
//     });
// };
// const onApprove = async (data: any) => {
//   // Order is captured on the server and the response is returned to the browser
//   try {
//     const response = await fetch(
//       `http://localhost:3336/order/captuerOrder/${_id}`,
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           authorization: "Bearer " + localStorage.getItem("token"),
//         },
//         body: JSON.stringify({
//           orderID: data.orderID,
//           price: infoTicket.price,
//           ticket_id: infoTicket._id,
//           userBuy_id: userInfo.id,
//           userSell_id: infoTicket.user_id,
//           category: infoTicket.category,
//           seat: infoTicket.seat,
//         }),
//       }
//     );
//     if (response.status >= 400 && response.status < 500) {
//       window.close();
//       // return alert("للأسف تم شراء التذكرة من قبل مستخ");
//       toast({
//         title: "نعتذر منك تم شراء التذكرة من قبل مستخدم اخر",
//         status: "warning",
//         position: "bottom",
//         colorScheme: "pink",
//         isClosable: true,
//       });
//     }
//     await axios.put(`http://localhost:3336/ticket/purchase/${_id}`);

//     onOpen();
//     setTimeout(() => {
//       onClose();
//     }, 5000);

//     return await response.json();
//   } catch (err) {
//     toast({
//       title: "حدث الصفحة او قم بأعادة تسجيل الدخول",
//       status: "warning",
//       position: "bottom-left",
//       colorScheme: "pink",
//       isClosable: true,
//     });
//   }
// };
// const onError = (error: any) => {
//   // عرض الخطأ الذي حدث
//   console.log(error);
//   window.close();
// };
