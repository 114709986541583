import React, { useContext, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  useColorModeValue,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import "./app.css";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "./ContextUser";
import AuthContext from "../context/AuthContext";

function NavBar(props: any) {
  const { setUserInfo, userInfo } = UserContext();
  const { getLoggedIn, loggedIn } = useContext(AuthContext);

  const navigate = useNavigate();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const handleLogout = async () => {
    await axios.get("/user/logout").catch((err) => {
      console.log(err);
    });
    await getLoggedIn();
    setUserInfo({ _id: "" });
    localStorage.clear();
    navigate("/login");
  };

  return (
    <Box
      className="nav"
      // backgroundColor={""}
      shadow={"dark-lg"}
      position={"relative"}
      zIndex={200}
    >
      <Flex
        //
        bg={useColorModeValue("blackAlpha.700", "gray.800")}
        color={useColorModeValue("gray.100", "white")}
        minH={"60px"}
        // py={{ base: 2 }}
        // px={{ base: 1 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("black", "gray.100")}
        align={"center"}
        justify={"space-between"}
        padding={"5px"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
            _hover={{ background: "pink.300" }}
          />
        </Flex>
        <Flex justifyContent={"center"} marginRight={"50"}>
          <Image
            src={"/logo last chance8.png"}
            loading="lazy"
            alt="picture"
            w={"40px"}
            h={"40px"}
          />
        </Flex>

        <Flex
          width={"470px"}
          justify={"space-between"}
          display={{ base: "none", md: "flex" }}
          mr={"2px"}
        >
          <Text className="btn-22" fontSize={"smaller"}>
            من نحن
          </Text>
          {!loggedIn ? (
            <Link to={"/login"}>
              <Text className="btn-22" fontSize={"smaller"}>
                صفحتي
              </Text>
            </Link>
          ) : (
            <Link to={`/pageuser/${userInfo._id}`}>
              <Text className="btn-22" fontSize={"smaller"}>
                صفحتي
              </Text>
            </Link>
          )}

          <Link to={"/"}>
            <Text className="btn-22">الرئيسية</Text>
          </Link>
        </Flex>
        {!loggedIn && (
          <Stack
            flex={{ base: 1, md: 0 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={2}
          >
            <Link to={"/login"}>
              <Button
                fontSize={"sm"}
                fontWeight={600}
                color={"white"}
                bg={"pink.400"}
                _hover={{
                  bg: "pink.300",
                }}
              >
                تسجيل الدخول
              </Button>
            </Link>
            <Link to={"/signup"}>
              <Button
                fontSize={"sm"}
                fontWeight={600}
                color={"white"}
                bg={"pink.400"}
                _hover={{
                  bg: "pink.300",
                }}
              >
                التسجيل
              </Button>
            </Link>
          </Stack>
        )}
        {loggedIn === true && (
          <Stack
            flex={{ base: 1, md: 0 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={2}
          >
            <Link to={"/login"}>
              <Button
                fontSize={"sm"}
                fontWeight={600}
                color={"white"}
                bg={"pink.400"}
                _hover={{
                  bg: "pink.300",
                }}
                onClick={handleLogout}
              >
                خروج
              </Button>
            </Link>
          </Stack>
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <Box>
          <Link to={"/"}>
            <Text
              onClick={onClose}
              textAlign={"right"}
              cursor={"pointer"}
              // p={1.5}
              pr={"5px"}
              pt={"5px"}
              pb={"5px"}
              fontWeight={"bold"}
              _hover={{ backgroundColor: "gray.200" }}
            >
              الرئيسية
            </Text>
          </Link>
          <hr style={{ borderColor: "black" }}></hr>
          {!localStorage.getItem("token") ? (
            <Link to={`/login`}>
              <Text
                onClick={onClose}
                pr={"5px"}
                pt={"5px"}
                pb={"5px"}
                textAlign={"right"}
                cursor={"pointer"}
                fontWeight={"bold"}
                _hover={{ backgroundColor: "gray.200" }}
              >
                صفحتي
              </Text>
            </Link>
          ) : (
            <Link to={`/pageuser/${userInfo._id}`}>
              <Text
                onClick={onClose}
                pr={"5px"}
                pt={"5px"}
                pb={"5px"}
                textAlign={"right"}
                cursor={"pointer"}
                // p={1.5}
                fontWeight={"bold"}
                _hover={{ backgroundColor: "gray.200" }}
              >
                صفحتي
              </Text>
            </Link>
          )}

          <hr style={{ borderColor: "black" }}></hr>
          <Text
            pr={"5px"}
            pt={"5px"}
            pb={"5px"}
            cursor={"pointer"}
            textAlign={"right"}
            fontWeight={"bold"}
            _hover={{ backgroundColor: "gray.200" }}
          >
            تواصل معنا
          </Text>
          <hr style={{ borderColor: "black" }}></hr>
        </Box>
      </Collapse>
    </Box>
  );
}

export default React.memo(NavBar);
