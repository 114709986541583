import axios from "axios";
import React, { useEffect, useState } from "react";
import { UserContext } from "../components/ContextUser";
import { useParams } from "react-router-dom";
import { Box } from "@chakra-ui/react";

function PageVISAMASTER() {
  const { _id } = useParams();
  const { userInfo } = UserContext();
  const [checkoutId, setCheckoutId] = useState("");
  const [paymentBrand, setPaymentBrand] = useState("VISA");
  const [loading, setLoading] = useState(false);
  const [transactionId, setTransactionId] = useState("");

  useEffect(() => {
    const makeform = () => {
      const head = document.getElementsByTagName("head")[0];
      // setLoading(true);
      // const head = document.getElementsByTagName("head")[0];
      axios
        .post(
          `/hyperpay/makePayment/${_id}`,
          {
            userBuy_id: userInfo._id,
            paymentBrand: paymentBrand,
            email: userInfo.email,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          setLoading(false);
          setCheckoutId(res.data.paymentResponse.id);
          setTransactionId(res.data.transactionId);
          // إنشاء وإضافة script جديد
          const script = document.createElement("script");
          script.src = `https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=${res.data.paymentResponse.id}`;
          head.appendChild(script);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    };
    if (_id) {
      makeform();
    }
  }, []);

  useEffect(() => {
    const checkBrand = () => {
      const visaBrand = document.querySelector(".wpwl-brand-VISA");
      const masterBrand = document.querySelector(".wpwl-brand-MASTER");

      if (visaBrand) {
        setPaymentBrand("VISA");
      } else if (masterBrand) {
        setPaymentBrand("MASTER");
      }
    };

    const interval = setInterval(checkBrand, 1000); // كل ثانية يتم فحص الحالة

    // تنظيف الفاصل الزمني عندما يتم تفريغ المكون أو عندما لا يكون هناك حاجة للفحص
    return () => {
      clearInterval(interval);
    };
  }, []);
  console.log(paymentBrand);
  return (
    <Box
      backgroundColor={"currentcolor"}
      minH={"71.7vh"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      {paymentBrand ? (
        <form
          action={`/shopperResultUrl/${checkoutId}/${paymentBrand}/${_id}/${transactionId}`}
          className="paymentWidgets"
          // onSubmit={makePaymentHyperPay}
          data-brands={"VISA MASTER"}
        ></form>
      ) : null}
    </Box>
  );
}

export default PageVISAMASTER;
