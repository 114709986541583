import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import NavBar from "./NavBar";
import UserProvider from "./ContextUser";
import Background from "./Background";
import Login from "../pages/Login";
import ConfirmUserPage from "../pages/ConfirmUserPage";
import Signup from "../pages/Signup";
import ForgetPass from "../pages/ForgetPass";
import EditPaa from "../pages/EditPaa";
import Home from "../pages/Home";
import TicketsPage from "../pages/TicketsPage";
import SaleTicket from "../pages/SaleTicket";
import PageUser from "../pages/PageUser";
import PayPage from "../pages/PayPage";
import PageMADA from "../pages/PageMADA";
import PageVISAMASTER from "../pages/PageVISAMASTER";
import ShopperResultUrl from "../pages/ShopperResultUrl";
import PageAdmin from "../pages/PageAdmin";
import AddEvent from "../pages/AddEvent";
import PageEventDelete from "../admin/PageEventDelete";
import Footer from "./Footer";
import PageNotFound from "../pages/PageNotFound";
import FooterLayout from "./FooterLayout";

function Routers() {
  return (
    <>
      <UserProvider>
        <BrowserRouter>
          <NavBar />
          <Routes>
            <Route element={<Background />}>
              <Route path="/login" element={<Login />} />
              <Route path="/confirm/:email" element={<ConfirmUserPage />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/forgetpass" element={<ForgetPass />} />
              <Route path="/user/editpass/:id/:token" element={<EditPaa />} />
            </Route>
            <Route element={<FooterLayout />}>
              <Route path="/" element={<Home />} />

              <Route path="/ticketspage/:_id" element={<TicketsPage />} />
              <Route path="/saleticket/:_id" element={<SaleTicket />} />
              <Route path="/pageuser/:id" element={<PageUser />} />
              <Route path="/paypage/:_id" element={<PayPage />} />
              <Route path="/pageMADA/:_id" element={<PageMADA />} />
              <Route
                path="/pageVISA&MASTER/:_id"
                element={<PageVISAMASTER />}
              />
              <Route
                path="/shopperResultUrl/:id/:paymentBrand/:_id/:transactionId"
                element={<ShopperResultUrl />}
              />
              {/* admin Route*/}
              <Route path="/pageadmin" element={<PageAdmin />} />
              <Route path="/addevent" element={<AddEvent />} />
              <Route path="/deleteevent" element={<PageEventDelete />} />
              {/* 404 page not found */}
              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </UserProvider>
    </>
  );
}

export default Routers;
