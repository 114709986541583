import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { Box, Icon, Text, VStack } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function ShopperResultUrl() {
  const { id, paymentBrand, _id, transactionId } = useParams();
  const [resultDesc, setResultDesc] = useState("");
  const [resultCodes, setResultCodes] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `/hyperpay/makePayment/${id}/${paymentBrand}/${_id}/${transactionId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        console.log(res);
        setResultDesc(res.data.result.description);
        setResultCodes(res.data.result.code);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        console.error(error);
      });
  }, []);
  return (
    <Box
      backgroundColor={"currentcolor"}
      minH={"71.7vh"}
      display={"flex"}
      alignItems={"start"}
      justifyContent={"center"}
    >
      {loading ? (
        <Text>loading...</Text>
      ) : resultCodes === "000.000.000" ? (
        <VStack gap={"20px"} mt={"41px"}>
          <CheckCircleIcon w={"100px"} h={"100px"} color="green.500" />
          <Text color={"white"} fontSize={"2xl"} fontWeight={"bold"}>
            تم شراء التذكرة بنجاح سيتم ارسالها الى ايميلك وستكون معروضة في صفحتي
          </Text>
        </VStack>
      ) : (
        <VStack gap={"20px"} mt={"41px"}>
          <Icon
            as={WarningIcon}
            w={"100px"}
            h={"100px"}
            color="red.500"
            ml={2}
          />
          <Text color={"white"} fontSize={"2xl"} fontWeight={"bold"}>
            فشلت عملية الدفع
          </Text>
          <Text color={"white"} fontSize={"2xl"} fontWeight={"bold"}>
            {resultDesc}
          </Text>
        </VStack>
      )}
    </Box>
  );
}

export default ShopperResultUrl;
