import * as React from "react";
import { ChakraProvider, theme } from "@chakra-ui/react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import NavBar from "./components/NavBar";
import Background from "./components/Background";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Home from "./pages/Home";
import ForgetPass from "./pages/ForgetPass";
import EditPaa from "./pages/EditPaa";
import "./components/app.css";
import TicketsPage from "./pages/TicketsPage";
import SaleTicket from "./pages/SaleTicket";
import UserProvider from "./components/ContextUser";
import PageUser from "./pages/PageUser";
import PayPage from "./pages/PayPage";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import PageAdmin from "./pages/PageAdmin";
import AddEvent from "./pages/AddEvent";
import PageNotFound from "./pages/PageNotFound";
import Footer from "./components/Footer";
import ShopperResultUrl from "./pages/ShopperResultUrl";
import PageMADA from "./pages/PageMADA";
import PageVISAMASTER from "./pages/PageVISAMASTER";
import ConfirmUserPage from "./pages/ConfirmUserPage";
import PageEventDelete from "./admin/PageEventDelete";
import Routers from "./components/Routers";
import { AuthContextProvider } from "./context/AuthContext";

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <AuthContextProvider>
        <Routers />
      </AuthContextProvider>
    </ChakraProvider>
  );
};
