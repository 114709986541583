import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Checkbox,
  useToast,
  InputRightElement,
  InputGroup,
  VStack,
  HStack,
} from "@chakra-ui/react";
import jwt, { JwtPayload } from "jwt-decode";
import { ViewIcon, ViewOffIcon, WarningIcon } from "@chakra-ui/icons";
import { WarningTwoIcon } from "@chakra-ui/icons";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { UserContext } from "../components/ContextUser";
import AuthContext from "../context/AuthContext";
interface UserData {
  email: string;
  password: string;
}
interface UserInfo {
  id: string;
  username: string;
  email: string;
}
function Login() {
  const navigate = useNavigate();
  const [err, seterr] = useState("");
  const { getLoggedIn } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { getUser } = UserContext();
  const [userData, setUserData] = useState<UserData>({
    email: "",
    password: "",
  });
  const login = async () => {
    setLoading(true);
    await axios
      .post("/user/login", {
        email: userData.email,
        password: userData.password,
      })
      .then(async (res) => {
        const data = await res.data;
        await getLoggedIn();
        await getUser();
        setLoading(false);

        // const tt: any = localStorage.getItem("token");
        if (res.status >= 200 || res.status < 300) {
          navigate("/");
          // const decodeToken: any = jwt<JwtPayload>(tt);
          // const { id, username, email } = decodeToken as UserInfo;
          // setUserInfo({ id, username, email });
          return data;
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        seterr(err.response.data.message);
      });
  };

  const handlerChange = (e: any) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  return (
    <Flex width={"100%"} justify={"center"} color={"black"}>
      <Stack
        spacing={8}
        mx={"auto"}
        maxW={"lg"}
        py={12}
        px={6}
        mt={"5px"}
        align={"center"}
      >
        <Stack align={"center"} position={"relative"} bottom={"60px"}>
          <Heading fontSize={"4xl"} color={"white"}>
            تسجيل الدخول
          </Heading>
          {err ? (
            <HStack
              justifyContent={"space-around"}
              bg={"red.300"}
              // w={"370px"}
              spacing={4}
              borderRadius={"2xl"}
              // p={"3px"}
              border={"1px solid red"}
              dir="rtl"
            >
              <Box p={"2px"}>
                <WarningIcon fontSize={"2xl"} color={"red"} />
              </Box>
              <Box p={"5px"}>
                <Text
                  dir="rtl"
                  fontSize={"lg"}
                  fontWeight={"semibold"}
                  color={"black"}
                >
                  {err}
                </Text>
              </Box>
            </HStack>
          ) : (
            ""
          )}
        </Stack>
        <Box
          w={"300px"}
          backgroundColor={"whiteAlpha.100"}
          shadow={"dark-lg"}
          position={"relative"}
          zIndex={20000000}
          bottom={"80px"}
          rounded={"lg"}
          p={8}
        >
          <Stack spacing={4} backgroundColor={"transparent"}>
            <FormControl isRequired id="email">
              <FormLabel color={"white"} textAlign={"right"}>
                الايميل
              </FormLabel>
              <Input
                bg={"transparent"}
                color={"white"}
                placeholder="XX@hotmail.com"
                type="email"
                value={userData.email}
                name={"email"}
                onChange={handlerChange}
              />
            </FormControl>

            <FormControl isRequired id="password">
              <FormLabel color={"white"} textAlign={"right"}>
                كلمة المرور
              </FormLabel>
              <InputGroup>
                <Input
                  color={"white"}
                  bg={"transparent"}
                  placeholder="XXXXXXXXX"
                  // type="password"
                  type={showPassword ? "text" : "password"}
                  value={userData.password}
                  name={"password"}
                  onChange={handlerChange}
                />
                <InputRightElement h={"full"}>
                  <Button
                    color={"white"}
                    variant={"ghost"}
                    _hover={{
                      color: "black",
                      backgroundColor: "white",
                    }}
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }
                  >
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Stack spacing={10}>
              <Stack
                direction={{ base: "column", sm: "row" }}
                // align={"start"}
                justify={"right"}
              >
                <Link to={"/forgetpass"}>
                  <Text
                    cursor={"pointer"}
                    textAlign={"right"}
                    color={"blue.400"}
                  >
                    تغيير كلمة المرور؟
                  </Text>
                </Link>
              </Stack>
              <Button
                isLoading={loading}
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
                onClick={login}
              >
                تسجيل دخول
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}

export default Login;
